import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@optimizely/axiom';

import { isFeatureEnabled } from '@optimizely/js-sdk-lab/src/actions';

const EmptyMetrics = ({ addMetricHandler }) => {
  return isFeatureEnabled('metrics_hub') ? (
    <div className="flex flex--column flex--dead-center border-radius push-double--ends empty-metrics-picker">
      <h8 className="push flush--bottom">No Metrics added</h8>
      <span className="text--center muted push--top">
        Start by searching for your metrics and adding them to your experiment.
        Need to create new metrics? Click the "Manage Metrics" button.
      </span>
    </div>
  ) : (
    <div className="flex flex--column flex-justified--center flex-align--center border-radius push-double--ends empty-metrics-picker">
      <h4 className="push flush--bottom">No Metrics Found</h4>
      <span>Please add a metric to measure the success of this experiment</span>
      <Button
        style="outline"
        className="push-double--top"
        onClick={addMetricHandler}
        leftIcon="add">
        Add Metric
      </Button>
    </div>
  );
};

EmptyMetrics.propTypes = {
  addMetricHandler: PropTypes.func.isRequired,
};

export default EmptyMetrics;
